import React, { useEffect, useState } from 'react';
import { MuseClient } from 'muse-js';
import Plot from 'react-plotly.js';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import ArduinoControl from '../arduino_util/arduinoControl.js';

const RecordingPage = () => {
  // const [eegData, setEegData] = useState({
  //   time: [],
  //   timeWeb: [],
  //   signal0: [],
  //   signal1: [],
  //   signal2: [],
  //   signal3: [],
  // });

  // const [ppgData, setPpgData] = useState({
  //   time: [],
  //   timeWeb: [],
  //   ppg1: [],
  //   ppg2: [],
  //   ppg3: [],
  // });

  // const [accData, setAccData] = useState({
  //   time:  [],
  //   x_acc: [],
  //   y_acc: [],
  //   z_acc: [],
  // });

  // const [gyroData, setGyroData] = useState({
  //   time:  [],
  //   x_gyro: [],
  //   y_gyro: [],
  //   z_gyro: [],
  // });

  // plotting state variables
  const [eegPlotData, setEegPlotData] = useState({
    time: [],
    signal0: [],
    signal1: [],
    signal2: [],
    signal3: [],
  });

  const [ppgPlotData, setPpgPlotData] = useState({
   time: [],
   ppg1: [],
   ppg2: [],
   ppg3: [],
 });

  const [accPlotData, setAccPlotData] = useState({
    time:  [],
    x_acc: [],
    y_acc: [],
    z_acc: [],
  });

  const [gyroPlotData, setGyroPlotData] = useState({
    time:  [],
    x_gyro: [],
    y_gyro: [],
    z_gyro: [],
  });  

  const [evtData, setEvtData] = useState({
    time:  [],
    value: [],
  });  

  // other state variables
  const [isConnected, setIsConnected] = useState(false);
  const [museClient, setMuseClient] = useState(null);
  const [subjectName, setSubjectName] = useState(""); // State for the subject's name
  const [uploadIntervalId, setUploadIntervalId] = useState(null);
  const [connectionIntervalId, setConnectionIntervalId] = useState(null);
  const [arduinoControl, setArduinoControl] = useState(null);
 

  // data upload buffer variables
  let activeEEGData = { time: [], timeWeb: [], signal0: [], signal1: [], signal2: [], signal3: [] };
  let activePPGData = { time: [], timeWeb: [], ppg1: [], ppg2: [], ppg3: [] };
  let activeAccData = { time: [], x_acc: [], y_acc: [], z_acc: [] };
  let activeGyroData = { time: [], x_gyro: [], y_gyro: [], z_gyro: [] };
  let activeEvtData = { time: [], value: []};
  
  // arduino variables
  const arduinoControlInstance=new ArduinoControl();
  // const listener = (value) => { // arduino value listener
  //   setArduinoData((prevData) => [...prevData.slice(-999), Number(value)]); // Keep the last 1000 values
  //   let timestampWeb = new Date();
  //   activeArduinoData.time.push(timestampWeb.getTime());
  //   activeArduinoData.value.push(Number(value));
  // };

  // muse client variables
  const mclient = new MuseClient();
  mclient.enablePpg = true;
  let bluetoothdevice=null;

  // subject name variables
  let subjectNamee=null;
  const setSubjectNameFunc = (namee)=>{
    setSubjectName(namee)
    subjectNamee=namee;
  }

  // -------------------------------------------------------------------- functions -----------------------------------------------------------------------------
  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_BUCKET_NAME;

  const calculateAverage = (arr)=> {
    if (arr.length === 0) return 0;
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  }

  const limitLength = (array, maxLength) => array.slice(-maxLength);

  const initializeConnection = async () => {
    try {
      
      await arduinoControlInstance.connect();
      console.log(arduinoControlInstance.port);
      setArduinoControl(arduinoControlInstance);

      console.log('Connecting...');
      await mclient.connect();
      console.log('Starting...');
      await mclient.start();
      setMuseClient(mclient);
      console.log(mclient)
      console.log(mclient.gatt)
      bluetoothdevice=mclient.gatt.device;
      setIsConnected(true);
      startReading(mclient);

      mclient.gatt.device.addEventListener('gattserverdisconnected', ()=>{
        let currTime = new Date();
        console.log(
          'Connection stopped at ' +
            currTime.getFullYear() +
            '/' +
            (currTime.getMonth() + 1) +
            '/' +
            currTime.getDate() +
            ',' +
            currTime.getHours() +
            ':' +
            currTime.getMinutes() +
            ':' +
            currTime.getSeconds()
        );
        console.log(isConnected)
        const reconnectIfNeeded = async () => {
          if (isConnected) {
            try {
              const newGatt = await bluetoothdevice.gatt.connect();
              mclient.gatt = newGatt;
              await mclient.connect(mclient.gatt);
              await mclient.start();
            } catch (error) {
              console.error('Reconnection failed:', error);
            }
          }
        }
        reconnectIfNeeded();
      });

      // Start timed upload
      const intervalId = setInterval(() => {  
        uploadWarpper();
      }, 6 * 60 * 1000); // Every 6 minutes

      setUploadIntervalId(intervalId); // Store interval ID

    } catch (error) {
      console.error("Connection failed:", error);
      alert("Unable to connect to the Muse device");
      return;

    }
  };

  const startReading = (client) => {
    // arduinoControlInstance.addListener(listener);

    client.ppgReadings.subscribe((ppgReading) => {
     let timestampWeb = new Date();
     let timestampWebSec=timestampWeb.getTime();

     const { timestamp, ppgChannel, samples } = ppgReading;
      // setPpgData((prevData) => ({
      //   ...prevData,
      //   time: [...prevData.time, timestamp],
      //   timeWeb: [...prevData.timeWeb, timestampWebSec],
      //   ppg1: [...prevData.ppg1, ppgChannel === 0 ? samples : null],
      //   ppg2: [...prevData.ppg2, ppgChannel === 1 ? samples : null],
      //   ppg3: [...prevData.ppg3, ppgChannel === 2 ? samples : null],
      // }));

     activePPGData.time.push(timestamp);
     activePPGData.timeWeb.push(timestampWebSec);
     if(ppgChannel === 0){
       activePPGData.ppg1.push(samples);
       activePPGData.ppg2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
       activePPGData.ppg3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(ppgChannel === 1){
        activePPGData.ppg1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activePPGData.ppg2.push(samples);
        activePPGData.ppg3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(ppgChannel === 2){
        activePPGData.ppg1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activePPGData.ppg2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activePPGData.ppg3.push(samples);
      }
  
      setPpgPlotData((prevData) => ({
        ...prevData,
        time: limitLength([...prevData.time, timestampWebSec], 1000),
        ppg1: limitLength([...prevData.ppg1, ppgChannel === 0 ? calculateAverage(samples) : 0], 1000),
        ppg2: limitLength([...prevData.ppg2, ppgChannel === 1 ? calculateAverage(samples) : 0], 1000),
        ppg3: limitLength([...prevData.ppg3, ppgChannel === 2 ? calculateAverage(samples) : 0], 1000),
      }));

    });

    client.eegReadings.subscribe((reading) => {

      let timestampWeb = new Date();
      let timestampWebSec=timestampWeb.getTime();
      
      const { timestamp, electrode, samples } = reading;
      // setEegData((prevData) => ({
      //   ...prevData,
      //   time: [...prevData.time, timestamp],
      //   timeWeb: [...prevData.timeWeb, timestampWebSec],
      //   signal0: [...prevData.signal0, electrode === 0 ? samples : null],
      //   signal1: [...prevData.signal1, electrode === 1 ? samples : null],
      //   signal2: [...prevData.signal2, electrode === 2 ? samples : null],
      //   signal3: [...prevData.signal3, electrode === 3 ? samples : null],
      // }));

      activeEEGData.time.push(timestamp);
      activeEEGData.timeWeb.push(timestampWebSec);
      if(electrode === 0){
        activeEEGData.signal0.push(samples);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 1){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push(samples);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 2){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push(samples);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 3){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push(samples);
      }
      
      setEegPlotData((prevData) => ({
        ...prevData,
        time: limitLength([...prevData.time, timestampWebSec], 1000),
        signal0: limitLength([...prevData.signal0, electrode === 0 ? calculateAverage(samples) : 0], 1000),
        signal1: limitLength([...prevData.signal1, electrode === 1 ? calculateAverage(samples) : 0], 1000),
        signal2: limitLength([...prevData.signal2, electrode === 2 ? calculateAverage(samples) : 0], 1000),
        signal3: limitLength([...prevData.signal3, electrode === 3 ? calculateAverage(samples) : 0], 1000),
      }));
    });

    client.accelerometerData.subscribe((reading) => {
      //console.log(reading)
      const { samples } = reading;
      let timestampWeb = new Date();
      let timestampWebSec=timestampWeb.getTime();
      // setAccData((prevData) => ({
      //   ...prevData,
      //   time: [...prevData.time, timestampWebSec],
      //   ...({ x: [...prevData.x_acc, [samples['0']['x'], samples['1']['x'], samples['2']['x']]] }),
      //   ...({ y: [...prevData.y_acc, [samples['0']['y'], samples['1']['y'], samples['2']['y']]] }),
      //   ...({ z: [...prevData.z_acc, [samples['0']['z'], samples['1']['z'], samples['2']['z']]] }),
      // }));

      activeAccData.time.push(timestampWebSec);
      activeAccData.x_acc.push([samples['0']['x'], samples['1']['x'], samples['2']['x']]);
      activeAccData.y_acc.push([samples['0']['y'], samples['1']['y'], samples['2']['y']]);
      activeAccData.z_acc.push([samples['0']['z'], samples['1']['z'], samples['2']['z']]);

      setAccPlotData((prevData) => ({
        ...prevData,
        time: limitLength([...prevData.time, timestampWebSec], 1000),
        x_acc: limitLength([...prevData.x_acc, calculateAverage([samples['0']['x'], samples['1']['x'], samples['2']['x']])], 1000),
        y_acc: limitLength([...prevData.y_acc, calculateAverage([samples['0']['y'], samples['1']['y'], samples['2']['y']])], 1000),
        z_acc: limitLength([...prevData.z_acc, calculateAverage([samples['0']['z'], samples['1']['z'], samples['2']['z']])], 1000),
      }));
    });

    client.gyroscopeData.subscribe((reading) => {
      //console.log(reading)
      const { samples } = reading;
      let timestampWeb = new Date();
      let timestampWebSec=timestampWeb.getTime();
      // setGyroData((prevData) => ({
      //   ...prevData,
      //   time: [...prevData.time, timestampWebSec],
      //   ...({ x: [...prevData.x_gyro, [samples['0']['x'], samples['1']['x'], samples['2']['x']]] }),
      //   ...({ y: [...prevData.y_gyro, [samples['0']['y'], samples['1']['y'], samples['2']['y']]] }),
      //   ...({ z: [...prevData.z_gyro, [samples['0']['z'], samples['1']['z'], samples['2']['z']]] }),
      // }));

      activeGyroData.time.push(timestampWebSec);
      activeGyroData.x_gyro.push([samples['0']['x'], samples['1']['x'], samples['2']['x']]);
      activeGyroData.y_gyro.push([samples['0']['y'], samples['1']['y'], samples['2']['y']]);
      activeGyroData.z_gyro.push([samples['0']['z'], samples['1']['z'], samples['2']['z']]);

      setGyroPlotData((prevData) => ({
        ...prevData,
        time: limitLength([...prevData.time, timestampWebSec], 1000),
        x_gyro: limitLength([...prevData.x_gyro, calculateAverage([samples['0']['x'], samples['1']['x'], samples['2']['x']])], 1000),
        y_gyro: limitLength([...prevData.y_gyro, calculateAverage([samples['0']['y'], samples['1']['y'], samples['2']['y']])], 1000),
        z_gyro: limitLength([...prevData.z_gyro, calculateAverage([samples['0']['z'], samples['1']['z'], samples['2']['z']])], 1000),
      }));
    });

    console.log("Started reading EEG, PPG, acc, gryo data.");
  };

  const handleArduinoMessage = (message) =>{
      console.log(arduinoControl.port);
      let timestampWeb = new Date();
      setEvtData((prevData) => ({
        ...prevData,
        time: [...prevData.time, timestampWeb.getTime()],
        ...({ value: [...prevData.value, message?1:0] }),
      }));
      arduinoControl.sendMessage(message)
  }

  const stopConnection = () => {
    //uploadWarpper();
    setIsConnected(false);

    // arduinoControlInstance.removeListener(listener);
    if(arduinoControl){
      arduinoControl.disconnect(); // Disconnect when the component unmounts
    }
    

    if (museClient) {
      museClient.disconnect();
      setMuseClient(null);
      console.log('Connection stopped.');
    }

    if (uploadIntervalId) {
      clearInterval(uploadIntervalId); // Clear the interval
      setUploadIntervalId(null); // Reset interval ID
      console.log('Upload stopped.');
    }
    if (connectionIntervalId) {
      clearInterval(connectionIntervalId); // Clear the interval
      setConnectionIntervalId(null); // Reset interval ID
      console.log('reconnection check stopped.');
    }

    let currTime=new Date();
    console.log('Connection stop at '+currTime.getFullYear()+'/'+currTime.getMonth()+'/'+currTime.getDay()+','+currTime.getHours()+':'+currTime.getMinutes()+':'+currTime.getSeconds());
  };

  const uploadWarpper = ()=>{
        // Perform the timed upload logic here
        const saveTime = new Date();

        //console.log(activePPGData)
        console.log(activeEEGData)
        console.log(activeAccData)
        console.log(activeGyroData)
        console.log(activeEvtData)
        
        activePPGData.time=limitLength(activePPGData.time,2000*6);
        activePPGData.timeWeb=limitLength(activePPGData.timeWeb,2000*6);
        activePPGData.time=limitLength(activePPGData.time,2000*6);
        activePPGData.time=limitLength(activePPGData.time,2000*6);
        activePPGData.time=limitLength(activePPGData.time,2000*6);

        activeEEGData.time=limitLength(activeEEGData.time,5400*6);
        activeEEGData.timeWeb=limitLength(activeEEGData.timeWeb,5400*6);
        activeEEGData.signal0=limitLength(activeEEGData.signal0,5400*6);
        activeEEGData.signal1=limitLength(activeEEGData.signal1,5400*6);
        activeEEGData.signal2=limitLength(activeEEGData.signal2,5400*6);
        activeEEGData.signal3=limitLength(activeEEGData.signal3,5400*6);

        activeAccData.time=limitLength(activeAccData.time,1200*6);
        activeAccData.x_acc=limitLength(activeAccData.x_acc,1200*6);
        activeAccData.y_acc=limitLength(activeAccData.y_acc,1200*6);
        activeAccData.z_acc=limitLength(activeAccData.z_acc,1200*6);

        activeGyroData.time=limitLength(activeGyroData.time,1200*6);
        activeGyroData.x_gyro=limitLength(activeGyroData.x_gyro,1200*6);
        activeGyroData.y_gyro=limitLength(activeGyroData.y_gyro,1200*6);
        activeGyroData.z_gyro=limitLength(activeGyroData.z_gyro,1200*6);

        // activeArduinoData.time=limitLength(activeArduinoData.time,1200*6);
        // activeArduinoData.value=limitLength(activeArduinoData.value,1200*6);

        const csvData = convertToCSV(activeEEGData, activePPGData);
        //const csvData = convertToCSV(activeEEGData,[]);
        const csvDataBehav = convertToCSVBehav(activeAccData, activeGyroData);
        const csvDataArduino = convertToCSVArduino(activeEvtData);

        let fileName = `${subjectName}_eeg_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
        uploadToS3(csvData, fileName);

        fileName = `${subjectName}_behav_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
        uploadToS3(csvDataBehav, fileName);

        fileName = `${subjectName}_arduino_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
        uploadToS3(csvDataArduino, fileName);

        // setEegData(activeEEGData);
        // setPpgData(activeEEGData);
        // setAccData(activeAccData);
        // setGyroData(activeGyroData);
  }

  const convertToCSV = (eegData, ppgData) => {
    const headers = ['Time', 'TimeWeb', 'Signal0', 'Signal1', 'Signal2', 'Signal3', 'PPG1', 'PPG2', 'PPG3'];
    const maxLength = Math.max(eegData.time.length, ppgData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      eegData.time[index] || ppgData.time[index] || '',
      eegData.timeWeb[index] || ppgData.timeWeb[index] || '',
      eegData.signal0[index] || '',
      eegData.signal1[index] || '',
      eegData.signal2[index] || '',
      eegData.signal3[index] || '',
      ppgData.ppg1[index] || '',
      ppgData.ppg2[index] || '',
      ppgData.ppg3[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const convertToCSVBehav = (accData, gyroData) => {
    const headers = ['Time','x_Acc','y_Acc','z_Acc','x_gyro','y_gyro','z_gyro'];
    const maxLength = Math.max(accData.time.length, gyroData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      accData.time[index] || gyroData.time[index] || '',
      accData.x_acc[index] || '',
      accData.y_acc[index] || '',
      accData.z_acc[index] || '',
      gyroData.x_gyro[index] || '',
      gyroData.y_gyro[index] || '',
      gyroData.z_gyro[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const convertToCSVArduino = (arduinoData) => {
    const headers = ['Time','value'];
    const maxLength = arduinoData.time.length;

    const rows = Array.from({ length: maxLength }, (_, index) => [
      arduinoData.time[index] || '',
      arduinoData.value[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const uploadToS3 = async (csvContent, fileName) => {

    try {
      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: csvContent,
        ContentType: "text/csv",
      };
      await client.send(new PutObjectCommand(params));
      console.log(`Uploaded data to S3: ${fileName}`);
    } catch (err) {
      console.error("Error uploading to S3:", err);
    }
  };

  return (
    <div className="main-page">
      <h1>EEG Recording Page</h1>
      
      {/* Subject name input */}
      <div style={{ margin: "20px 0" }}>
        <label>
          Subject Name:
          <input
            type="text"
            value={subjectName}
            onChange={(e) => {
              setSubjectNameFunc(e.target.value); // Updates the state
              console.log("Input Changed:", subjectNamee);
              console.log("Input Changed:", subjectName);
            }}
            placeholder="Enter subject name"
            style={{ marginLeft: "10px", padding: "5px" }}
          />
        </label>
      </div>

      {/* connection button */}
      {!isConnected ? (
        <button onClick={initializeConnection}>Connect EEG Device</button>
      ) : (
        <button onClick={stopConnection}>Stop Connection</button>
      )}
      {isConnected && <p>Connected to Muse device and reading EEG data...</p>}
  
      {/* Plot EEG data */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        {['signal0', 'signal1', 'signal2', 'signal3'].map((signal, index) => (
          <Plot
            key={signal}
            data={[
              {
                x: eegPlotData.time,
                y: eegPlotData[signal],
                type: 'scatter',
                mode: 'lines',
                line: { color: 'blue' },
              },
            ]}
            layout={{
              title: `EEG Signal - Channel ${index}`,
              xaxis: {
                title: 'Time',
              },
              yaxis: {
                title: 'EEG Signal',
              },
              margin: { l: 50, r: 20, t: 60, b: 50 }, // Adjust margins for reduced spacing
              titlefont: { size: 14 },
            }}
            style={{ width: '300px', height: '200px' }}
          />
        ))}
      </div>
  
      {/* Plot PPG data */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        {['ppg1', 'ppg2', 'ppg3'].map((ppg, index) => (
          <Plot
            key={ppg}
            data={[
              {
                x: ppgPlotData.time,
                y: ppgPlotData[ppg],
                type: 'scatter',
                mode: 'lines',
                line: { color: 'red' },
              },
            ]}
            layout={{
              title: `PPG Signal - Channel ${index + 1}`,
              xaxis: {
                title: 'Time',
              },
              yaxis: {
                title: 'PPG Signal',
              },
              margin: { l: 50, r: 20, t: 60, b: 50 }, // Adjust margins for reduced spacing
              titlefont: { size: 14 },
            }}
            style={{ width: '300px', height: '200px' }}
          />
        ))}
  
      {/* Plot accelerometer data */}
      <Plot
        data={[
          {
            x: accPlotData.time,
            y: accPlotData.x_acc,
            type: 'scatter',
            mode: 'lines',
            name: 'X',
            line: { color: 'red' },
          },
          {
            x: accPlotData.time,
            y: accPlotData.y_acc,
            type: 'scatter',
            mode: 'lines',
            name: 'Y',
            line: { color: 'green' },
          },
          {
            x: accPlotData.time,
            y: accPlotData.z_acc,
            type: 'scatter',
            mode: 'lines',
            name: 'Z',
            line: { color: 'blue' },
          },
        ]}
        layout={{
          title: 'Accelerometer Data',
          xaxis: {
            title: 'Time',
          },
          yaxis: {
            title: 'unit',
          },
          margin: { l: 50, r: 20, t: 60, b: 50 }, // Adjust margins for reduced spacing
          titlefont: { size: 14 },
          legend: { orientation: 'h' },
        }}
        style={{ width: '300px', height: '200px' }}
      />
  
      {/* Plot gyroscope data */}
      <Plot
        data={[
          {
            x: gyroPlotData.time,
            y: gyroPlotData.x_gyro,
            type: 'scatter',
            mode: 'lines',
            name: 'X',
            line: { color: 'orange' },
          },
          {
            x: gyroPlotData.time,
            y: gyroPlotData.y_gyro,
            type: 'scatter',
            mode: 'lines',
            name: 'Y',
            line: { color: 'purple' },
          },
          {
            x: gyroPlotData.time,
            y: gyroPlotData.z_gyro,
            type: 'scatter',
            mode: 'lines',
            name: 'Z',
            line: { color: 'cyan' },
          },
        ]}
        layout={{
          title: 'Gyroscope Data',
          xaxis: {
            title: 'Time',
          },
          yaxis: {
            title: 'unit',
          },
          margin: { l: 50, r: 20, t: 60, b: 50 }, // Adjust margins for reduced spacing
          titlefont: { size: 14 },
          legend: { orientation: 'h' },
        }}
        style={{ width: '300px', height: '200px' }}
      />

      {/* Plot arduino data 
      <Plot
        data={[
          {
            x: Array.from({ length: arduinoData.length }, (_, i) => i), // X-axis as index
            y: arduinoData,
            type: 'scatter',
            mode: 'lines+points',
          },
        ]}
        layout={{
          title: 'Real-Time Arduino Signal',
          xaxis: { title: 'Time' },
          yaxis: { title: 'Signal Value' },
        }}
        style={{ width: '300px', height: '200px' }}
      />*/}
    </div>
      {/* Arduino buttons*/}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        <button onClick={() => handleArduinoMessage(false)}>send 0 to stimulation</button>
        <button onClick={() => handleArduinoMessage(true)}>send 1 to stimulation</button>
      </div> 
    </div>
  );
};

export default RecordingPage;