import React, { useEffect, useState, useCallback } from 'react';
import Plot from 'react-plotly.js';
import Papa from 'papaparse';
import { S3Client, GetObjectCommand, ListObjectsCommand} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

// AnalysisPage Component
const AnalysisPage = () => {

    const [rawData, setRawData] = useState([]);
    const [averagedData, setAveragedData] = useState([]);
    const [fileName, setFileName] = useState("eeg_data_00:08:19.csv"); // Store the selected file

    const [files, setFiles] = useState([]); // Store the list of files
    const [loading, setLoading] = useState(true); // Tracks loading state
    const [error, setError] = useState(''); // Stores any error messages

    // AWS S3 Configuration
    const client = new S3Client({
        region: process.env.REACT_APP_REGION,
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: process.env.REACT_APP_REGION },
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        }),
    });
    const bucketName = process.env.REACT_APP_BUCKET_NAME;

    // function to collect file name in s3
    const fetchFileList = useCallback(async () => {
        setLoading(true); // Begin loading
        const command = new ListObjectsCommand({ Bucket: bucketName }); // Command to list objects in the bucket
        try {
          const { Contents } = await client.send(command); // Send command to S3 client
          console.log(Contents);
          setFiles(Contents || []); // Update state with the fetched objects
          setError(''); // Clear any errors
        } catch (err) {
          console.error('Error fetching data from S3:', err); // Log errors to console
          setError('Failed to load data.'); // Set error state
        } finally {
          setLoading(false); // End loading
        }
    }, [client]);
      
    // function to fetch data from s3
    const fetchCsvFromS3 = async (fileName) => {
        const params = {
          Bucket: bucketName,
          Key: fileName,
        };
        const command = new GetObjectCommand(params);
        const data = await client.send(command);
      
        // Use Response to convert the stream to text
        const csvData = await new Response(data.Body).text();
        return csvData;
    };

    // Process CSV Data
    const processCsvData = (csvData) => {
        const results = Papa.parse(csvData, { header: false }).data;
        const numEEGSignals = 4;
        const numPPGSignals = 3;
        const numEEGColumnsPerSignal = 12;
        const numPPGColumnsPerSignal = 6;

        // Determine number of rows
        const numRows = results.length;

        const averagedData = Array(numRows).fill(null).map(() => Array(numEEGSignals+numPPGSignals).fill(0));
        for (let i = 0; i < numRows; i++) {
            // time
            const signalColumns = results[i][0];
            averagedData[i][0] = signalColumns;
            
            // EEG
            for (let j = 1; j < numEEGSignals; j++) {
                const signalColumns = results[i].slice(j * numEEGColumnsPerSignal, (j + 1) * numEEGColumnsPerSignal);
                const avg = signalColumns.reduce((sum, value) => sum + parseFloat(value), 0) / numEEGColumnsPerSignal;
                averagedData[i][j] = avg;
            }

            // PPG
            for (let j = numEEGSignals+1; j < numEEGSignals+numPPGSignals; j++) {
                const signalColumns = results[i].slice(j * numPPGColumnsPerSignal, (j + 1) * numPPGColumnsPerSignal);
                const avg = signalColumns.reduce((sum, value) => sum + parseFloat(value), 0) / numPPGColumnsPerSignal;
                averagedData[i][j] = avg;
            }
        }
        
        return { rawData: results, averagedData };
    };

    // data loading mechanism
    const loadData = async (fileName) => {
        setLoading(true); // Start loading
        try {
            const csvData = await fetchCsvFromS3(fileName);
            const { rawData, averagedData } = processCsvData(csvData);
            setRawData(rawData);
            setAveragedData(averagedData);
            setError(''); // Clear error on success
        } catch (err) {
            console.error('Error loading CSV data:', err);
            setError('Failed to load file data.');
        } finally {
            setLoading(false); // End loading
        }
    };

    // Plot Raw Signal
    const plotRawSignal = (averagedData) => {
        const numRows = averagedData.length;
        const time = Array.from(Array(numRows).keys());
        const traces = [];
        const legendss=['eeg1','eeg2','eeg3','eeg4','ppg1','ppg2','ppg3'];
        
        for (let i = 1; i < averagedData[0].length; i++) {
            traces.push({
            x: time,
            y: averagedData.map(row => row[i]),
            mode: 'lines',
            name: `Signal ${legendss[i]}`,
            });
        }

        return (
            <Plot
                data={traces}
                layout={{ title: 'Raw Signals', xaxis: { title: 'Time' }, yaxis: { title: 'Amplitude' } }}
            />
        );
    };

    // Plot Spectrogram
    const plotSpectrogram = (averagedData) => {
        
    };


     // Handle file change in the dropdown
    const handleFileChange = (event) => {
        console.log(event.target.value);
        loadData(event.target.value);
        setFileName(event.target.value); // set function will refresh page, has to be called after load

    };

    useEffect(() => {
        fetchFileList(); // Fetch the file list on component mount
    }, []);

    useEffect(() => {
        if (fileName) {
            loadData(fileName); // Load the initial file
        }
    }, []);

    return (
    <div>
        <h1>Data AnalysisPage</h1>
        {loading ? (<p>Loading...</p>) : error ? (<p>{error}</p>) : (
        <select id="file-select" value={fileName} onChange={handleFileChange}>
            {files.map((file, index) => (
            <option key={index} value={file.Key}>
                {file.Key}
            </option>
            ))}
        </select>)}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div>{averagedData.length > 0 && plotRawSignal(averagedData)}</div>
            <div>{averagedData.length > 0 && plotSpectrogram(averagedData)}</div>
        </div>
    </div>

    );
};

export default AnalysisPage;