import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MainPage from './pages/MainPage.js';
import DataPage from './pages/DataPage.js';
import HardwarePage from './pages/HardwarePage.js';
import AnalysisPage from './pages/AnalysisPage.js';
import RecordingPage from './pages/RecordingPage.js';
import FileExchangePage from './pages/FileExchangePage.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("G-FN1ZP3D99C");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Router>
      <div>
        {/* Bootstrap Navbar with Horizontal Items */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ fontFamily: 'Arial, sans-serif' }}>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/data"
                >
                  EEG Data
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/hardware"
                >
                  EEG Hardware
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/recording"
                >
                  EEG Online Recording
                </Link>
              </li>
              {/*<li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/analysis"
                >
                  EEG Online Analysis
                </Link>
              </li>*/}
              <li className="nav-item">
                <Link
                  className="nav-link text-dark px-3"
                  style={{ textDecoration: 'none' }}
                  to="/fileExchange"
                >
                  File Exchange
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* Page Content */}
        <div>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/data" element={<DataPage />} />
            <Route path="/hardware" element={<HardwarePage />} />
            <Route path="/recording" element={<RecordingPage />} />
            <Route path="/analysis" element={<AnalysisPage />} />
            <Route path="/fileExchange" element={<FileExchangePage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;