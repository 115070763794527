class ArduinoControl {
  constructor() {
    this.port = null;
    this.reader = null;
    this.writer = null;
    this.arduinoValues = []; // Store all read values
    this.isConnected = false;
    this.listeners = []; // Listeners for notifying real-time updates
    this.keepReading = false; // Flag to control reading loop
  }

  async connect() {
    try {
      // Request a port and open a connection
      this.port = await navigator.serial.requestPort();
      await this.port.open({ baudRate: 9600 });

      // Create a writer for sending messages
      this.writer = this.port.writable.getWriter();

      // Set up the reader
      this.reader = this.port.readable.getReader();

      this.isConnected = true;
      console.log("Connected to Arduino");
      
      // Start the reading loop
      //this.keepReading = true;
      //this.readLoop();
    } catch (err) {
      console.error("Error connecting to Arduino:", err);
    }
  }

  async readLoop() {
    if (!this.port) {
      console.error("Port is not open.");
      return;
    }
  
    const decoder = new TextDecoder("utf-8");
  
    const readTask = async () => {
      try {
        const { value, done } = await this.reader.read();
        if (done || !this.keepReading) {
          console.log("Stream closed or reading stopped.");
          this.keepReading = false;
          return;
        }
  
        if (value) {
          const trimmedValue = decoder.decode(value).trim();
          this.arduinoValues.push(trimmedValue);
          //console.log(`Arduino: ${trimmedValue}`);
  
          // Notify listeners
          this.listeners.forEach((listener) => listener(trimmedValue));
        }
      } catch (err) {
        console.error("Error reading from Arduino:", err);
        this.keepReading = false; // Stop reading on error
      }
    };
  
    // Promise-based scheduling loop
    while (this.keepReading) {
      await readTask();
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 50ms before the next read
    }
  
    console.log("Stopped Arduino read loop.");
  }

  async sendMessage(inputValue) {
    if (!this.port) {
      console.error("Not connected to Arduino");
      return;
    }

    try {
      const message = inputValue ? '1' : '0'; // Send '1' if inputValue is truthy, otherwise send '0'
      const encoder = new TextEncoder();
      await this.writer.write(encoder.encode(`${message}\n`));
      console.log(`Sent to Arduino: ${message}`);
    } catch (err) {
      console.error("Error sending message:", err);
    }
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  removeListener(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  disconnect() {
    // Stop the read loop
    this.keepReading = false;
  
    // Cancel the reader and release its lock
    if (this.reader) {
      this.reader.cancel(); // Cancel ongoing read operations
      this.reader.releaseLock(); // Release the lock on the reader
      this.reader = null; // Set the reader to null
    }
  
    // Release the writer lock
    if (this.writer) {
      this.writer.releaseLock();
      this.writer = null; // Set the writer to null
    }
  
    // Close the serial port
    if (this.port) {
      this.port.close();
      this.port = null; // Set the port to null
    }
  
    this.isConnected = false;
    console.log("Disconnected from Arduino");
  }
}

export default ArduinoControl;