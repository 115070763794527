// this page is only for displaying recorded EEG data and download the data, not intended for upload data
// for upload, goes to file exchange page
import React, { useEffect, useState, useCallback } from "react";
import { ListObjectsCommand, PutObjectCommand, GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

function DataPage() {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDownFile, setSelectedDownFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(""); // State for password error
  const [downloadProgress, setDownloadProgress] = useState(0);

  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const correctPassword = process.env.REACT_APP_DOWNLOAD_PASSWORD;

  const fetchObjects = useCallback(async () => {
    setLoading(true);
    const command = new ListObjectsCommand({ Bucket: bucketName });
    try {
      const { Contents } = await client.send(command);
      setObjects(Contents || []);
      setError('');
    } catch (err) {
      console.error('Error fetching data from S3:', err);
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchObjects();
  }, []);

  const handleFileClick = (fileKey) => {
    setSelectedDownFile(fileKey);
    setShowModal(true);
  };

  const handleDownloadFile = async () => {
    if (password !== correctPassword) {
      setPasswordError("Incorrect password.");
      return;
    }
    setPasswordError("");
    setDownloadProgress(0);
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: selectedDownFile,
    });

    try {
      const { Body, ContentLength } = await client.send(command);
      const reader = Body.getReader();
      let downloadedBytes = 0;

      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            controller.enqueue(value);
            downloadedBytes += value.length;
            setDownloadProgress((downloadedBytes / ContentLength) * 100);
          }
          controller.close();
          reader.releaseLock();
        },
      });

      new Response(stream).blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = selectedDownFile;
        link.click();
        window.URL.revokeObjectURL(url);
        setShowModal(false);
        setSelectedDownFile(null);
      });
    } catch (err) {
      console.error('Error downloading file:', err);
      alert('Failed to download file.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setPassword(""); // Clear password field when closing modal
    setPasswordError("");
    setDownloadProgress(0);
  };

  return (
    <div>
      <h1>openmind's eeg data</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {objects.map((object, index) => (
            <li key={index}>
              <a href="#" onClick={() => handleFileClick(object.Key)}>
                {object.Key}
              </a>
            </li>
          ))}
        </ul>
      )}

      {showModal && (
        <div style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000, border: '1px solid black', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <button onClick={handleCloseModal} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}>×</button>
          <h2>{"Download File"}</h2>
          {
            <>
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '10px', width: '100%' }}
              />
              {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
              <button onClick={handleDownloadFile}>Start Download</button>
              <div style={{ marginTop: '10px', width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                <div style={{ width: `${downloadProgress}%`, height: '10px', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
              </div>
              <p>{Math.round(downloadProgress)}% downloaded</p>
            </>
          }
        </div>
      )}
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={handleCloseModal}></div>
      )}
    </div>
  );
}

export default DataPage;